<script setup>
  import { getCurrentInstance, onMounted, onBeforeUnmount, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    orderId: '',
    iframeUrl: '',
    iframeHeight: '',
    loading: false
  })

  // 获取发票
  const getOrderInvoice = () => {
    data.iframeUrl = `${process.env.VUE_APP_PAYMENT_API}services/orders/invoice/${data.orderId}`
  }

  const handleMessage = () => {
    data.loading = true
    const PAYMENT_API = process.env.VUE_APP_PAYMENT_API.replace(/\/+$/, '') // 去除末尾的 "/"
    if (event.origin === PAYMENT_API) {
      // console.log(event, 'event')
      const { data } = event
      if (data) {
        const iframeHeight = `${data.height + 100}px` // 计算高度
        updateIframeHeight(iframeHeight) // 更新到响应式状态
      }
    }
    setTimeout(() => {
      data.loading = false
    }, 100)
  }

  // 动态更新 iframe 高度
  const updateIframeHeight = (height) => {
    data.iframeHeight = height
    console.log(data.iframeHeight, 'data.iframeHeight')
  }

  onMounted(() => {
    data.orderId = useRoute().query.orderId || ''
    console.log(useRoute(), 'data.orderId')
    getOrderInvoice()
    window.addEventListener('message', handleMessage)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('message', handleMessage)
  })
</script>

<template>
  <div class="page-box" v-loading="data.loading">
    <iframe :src="data.iframeUrl" width="100%" :height="data.iframeHeight" frameborder="0"></iframe>
  </div>
</template>

<style lang="less" scoped>
  .page-box {
    width: 100%;
    min-height: 100vh;
  }
</style>
